<template>
  <v-container fluid>
    <v-row>
      <v-col class="mt-3" md="4">
        <date-range-field
            :dateType="dateToggle"
            :dense="true"
            :outlined="true"
            class-name="q-text-field shadow-0"
            @periodChange="dateChange"
        />
      </v-col>
      <v-spacer/>
      <v-col md="3" style="text-align: center">
        <v-btn-toggle
            v-model="dateToggle"
            :mandatory="mandatory"
            borderless
            class="q-tabs shadow-0 bordered"
            style="height: 40px !important;"
            tile
        >
          <v-btn height="40" value="DATE">Day</v-btn>
          <v-btn height="40" value="MONTH">Month</v-btn>
          <v-btn height="40" value="YEAR">Year</v-btn>
        </v-btn-toggle>
      </v-col>
      <v-spacer/>
      <v-col md="4">
       <div class="d-flex align-center justify-end gap-x-4">
         <v-select
             v-if="showProductType"
             v-model="productTypeIds"
             :items="productTypes"
             :menu-props="{ bottom: true, offsetY: true }"
             background-color="#fff"
             item-text="name"
             item-value="id"
             placeholder="Product Type"
             class="q-autocomplete shadow-0"
             style="max-width: 180px !important;"
             multiple
             outlined
             required
             @change="callGraph"
             hide-details="auto"
             dense
         >
           <template
               v-if="productTypeIds.length == productTypes"
               v-slot:selection="{ index }"
           >
             <span v-if="index == 0">All</span>
           </template>
           <template v-else v-slot:selection="{ item, index }">
             <span v-if="index == 0">{{ item.name }}</span>
             <span v-if="index == 1">, ...</span>
           </template>
         </v-select>
         <v-select
             v-if="showVenueService"
             v-model="venueServiceIds"
             :items="venueServices"
             :menu-props="{ bottom: true, offsetY: true }"
             background-color="#fff"
             class="q-autocomplete shadow-0"
             dense
             hide-details
             item-text="name"
             item-value="venue_service_id"
             multiple
             outlined
             placeholder="Service"
             required
             style="max-width: 180px !important;"
             @change="callGraph"
         >
           <template
               v-if="venueServiceIds.length === venueServices.length"
               v-slot:selection="{ index }"
           >
             <span v-if="index === 0">All</span>
           </template>
           <template v-else v-slot:selection="{ item, index }">
             <span v-if="index === 0" class="text-elepsis">{{ item.name }}</span>
             <span v-if="index === 1" class="text-elepsis">, ..</span>
           </template>
         </v-select>
         <v-select
             v-if="showProductType"
             v-model="stack"
             :items="stackTypesItems"
             :menu-props="{ bottom: true, offsetY: true }"
             background-color="#fff"
             class="q-autocomplete shadow-0"
             dense
             hide-details
             item-text="name"
             item-value="value"
             outlined
             placeholder="Stack Type"
             required
             style="max-width: 180px !important;"
             @change="callGraph"
         />
       </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <BarChart
            :exportPermission="exportPermission"
            v-bind="bookings"
        ></BarChart>
      </v-col>

      <v-col cols="12">
        <BarChart
            :exportPermission="exportPermission"
            v-bind="sales"
        ></BarChart>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BarChart from "./BarChart";
import moment from "moment";
import DateRangeField from "@/components/Fields/DateRangeField.vue";

export default {
  components: {
    DateRangeField,
    BarChart,
  },
  props: {
    params: {
      type: Object,
      default: () => {
        return {venue_service_ids: [], product_ids: [], product_type_ids: []};
      },
    },
    "export-permission": {type: Boolean, default: true},
    "show-venue-service": {type: Boolean, default: true},
    "show-product-type": {type: Boolean, default: false},
    "stack-type": {type: String, default: "all"}, //service,type,all
    reload: {type: Boolean, default: false},
  },
  watch: {
    params: {
      immediate: true,
      handler(params) {
        if (params.venue_service_ids.length > 0) {
          this.venueServiceIds = params.venue_service_ids;
        }
        if (params.product_type_ids.length > 0) {
          this.productTypeIds = params.product_type_ids;
        }
        if (params.product_ids.length > 0) {
          this.productIds = params.product_ids;
        }
      },
    },
    stackType: {
      immediate: true,
      handler() {
        this.callGraph();
      },
    },
    stack(val) {
      this.$emit("stackChange", val);
    },
    reload: {
      immediate: true,
      handler(val) {
        if (val) {
          this.callGraph();
        }
      },
    },
  },
  data() {
    return {
      agePieChart: null,
      genderPieChart: null,
      countryPieChart: null,
      dateToggle: "DATE",
      venueServiceIds: [],
      productTypeIds: [],
      productIds: [],
      date1: moment()
          .subtract(15, "days")
          .format("YYYY-MM-DD"),
      date2: moment().format("YYYY-MM-DD"),
      bookings: {
        heading: "Top 5 Partners | Bookings",
        type: "totalBooking",
        fullscreenFlag: true,
        yTitle: "Bookings",
        xTitle: "Partners",
        chartData: [],
        exportData: [],
        loader: true,
        heighest: 0,
      },
      sales: {
        heading: "Top 5 Partners | Sales",
        type: "totalSales",
        fullscreenFlag: true,
        yTitle: "Sales",
        xTitle: "Partners",
        chartData: [],
        exportData: [],
        loader: true,
        heighest: 0,
      },
      stack: "all",
      mandatory: false,
      stackTypesItems: [
        {name: "All", value: "all"},
        {name: "Product Type", value: "type"},
        {name: "Service", value: "service"},
      ],
    };
  },
  mounted() {
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices");
    }
    if (this.$store.getters.getProductTypes.status == false) {
      this.$store.dispatch("loadProductTypes");
    }
  },
  computed: {
    venueServices() {
      return this.$store.getters.getVenueServices.data;
    },
    productTypes() {
      return this.$store.getters.getProductTypes.data.filter((element) => {
        return [3, 6].includes(element.id);
      });
    },
  },
  methods: {
    callGraph() {
      this.getOrderSalesGraphData();
      this.getOrderBookingGraphData();
    },
    dateChange(data) {
      this.date1 = data.date1;
      this.date2 = data.date2;
      this.callGraph();
    },
    getOrderSalesGraphData() {
      this.sales.loader = true;
      let url = "?type=sales";
      url += this.getApiFilter();
      this.$http
          .get(`venues/b2b/orders${url}`)
          .then((response) => {
            if (response.status == 200) {
              const data = response.data.data;
              let chartData = this.getGraphData(data, "Sales");
              this.sales.heighest = Math.ceil(chartData.largest * 1.1);
              this.sales.chartData = chartData.data;
              this.sales.exportData = data;
              this.sales.loader = false;
              this.mandatory = true;
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    getOrderBookingGraphData() {
      this.bookings.loader = true;
      let url = "?type=bookings";
      url += this.getApiFilter();
      this.$http
          .get(`venues/b2b/orders${url}`)
          .then((response) => {
            if (response.status == 200) {
              const data = response.data.data;
              let chartData = this.getGraphData(data, "Bookings");
              this.bookings.heighest = Math.ceil(chartData.largest * 1.1);
              this.bookings.chartData = chartData.data;
              this.bookings.exportData = data;
              this.bookings.loader = false;
              this.mandatory = true;
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    getApiFilter() {
      let url = `&date_type=${this.dateToggle.toLowerCase()}&start_date=${
          this.date1
      }&end_date=${this.date2}&stack_type=${this.stackType}`;

      if (
          this.venueServiceIds.length != this.venueServices.length &&
          this.venueServiceIds.length > 0
      ) {
        url += `&${this.venueServiceIds
            .map((n, index) => `venue_service_ids[${index}]=${n}`)
            .join("&")}`;
      }

      if (
          this.productTypeIds.length != this.productTypes.length &&
          this.productTypeIds.length > 0
      ) {
        url += `&${this.productTypeIds
            .map((n, index) => `product_type_ids[${index}]=${n}`)
            .join("&")}`;
      }

      if (this.productIds.length > 0) {
        url += `&${this.productIds
            .map((n, index) => `product_ids[${index}]=${n}`)
            .join("&")}`;
      }

      return url;
    },
    getGraphData(data, type) {
      var graphData = [];
      let largest = 0;
      if (data.length > 0) {
        let keys = Object.keys(data[0]).filter(
            (ele) => ele.toLowerCase() != "entity"
        );
        let keysLength = keys.length;
        data.forEach((element, index) => {
          let di = 0;
          let lar = 0;
          for (let i = 0; i <= keysLength; i++) {
            if (index == 0) {
              graphData[i] = {};
              graphData[i].data = {};
              graphData[i].name = keys[i];
              if (element[keys[i]]) {
                lar = lar + parseInt(element[keys[i]]);
              }
              graphData[i].data[element.Entity] = element[keys[i]];
              graphData[keysLength] = {};
              graphData[keysLength].name = "Total";
              graphData[keysLength].data = {};
              graphData[keysLength].data[element.Entity] = 0;
              di = di + 1;
            } else {
              if (element[keys[i]]) {
                lar = lar + parseInt(element[keys[i]]);
              }
              graphData[i].data[element.Entity] = element[keys[i]];
              di = di + 1;
            }
          }
          graphData[keysLength].data[element.Entity] = 0;
          if (largest < lar) {
            largest = lar;
          }
        });
        if (keysLength == 1 && keys[0] == type) {
          graphData[0].library = {};
          graphData[0].library.datalabels = {
            color: "rgba(0,0,49)",
            align: "end",
            anchor: "end",
            font: {
              size: 12,
              weight: 700,
            },
          };
        }
        if (this.stackType != "all") {
          graphData[keysLength].library = {};
          graphData[keysLength].library.datalabels = {
            color: function () {
              return "rgba(0,0,49)";
            },
            formatter: (value, ctx) => {
              let total = ctx.chart.$totalizer.totals[ctx.dataIndex];
              if (type == "Bookings") {
                let data = new Intl.NumberFormat("en-US", {
                  maximumSignificantDigits: 4,
                }).format(total);
                return data + (total > 1 ? " bookings" : " booking");
              } else if (type == "Sales") {
                var formatter = new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: this.$store.getters.currencyCode,
                  minimumFractionDigits: 0,
                });
                return formatter.format(total);
              } else {
                return new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: this.$store.getters.currencyCode,
                  // maximumSignificantDigits: 3,
                }).format(total);
              }
            },
            font: {
              size: 12,
              weight: 700,
            },
            align: "end",
            anchor: "end",
            display: function (ctx) {
              return (
                  ctx.datasetIndex === ctx.chart.$totalizer.utmost &&
                  ctx.chart.$totalizer.totals[ctx.dataIndex] !== 0
              );
            },
          };
        }
      }
      return {data: graphData, largest: largest};
    },
  },
};
</script>

<style></style>
