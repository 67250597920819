<template>
  <v-row>
    <v-col lg="3" md="6" sm="6">
      <v-card class="rounded-lg shadow">
        <div>
          <div class="p-3 bg-blue text-white rounded-t d-flex justify-space-between align-center">
            <SvgIcon class="font-semibold" text="Bookings">
              <template v-slot:icon>
                <BookingIcon/>
              </template>
            </SvgIcon>
            <p class="text-sm mb-0">
              Year to Date
            </p>
          </div>
          <table class="dash_tbl table-striped summary-table">
            <tbody>
            <tr
                v-if="
                    checkWritePermission($modules.facility.management.slug) ||
                      checkWritePermission($modules.facility.schedule.slug)
                  "
            >
              <td class="text-left">Facility Bookings</td>
              <td class="text-right">
                {{
                  Number(typeof ytd_G != "undefined" ? ytd_G.bookings : 0)
                      | numberFormatter
                }}
              </td>
            </tr>
            <tr
                v-if="
                    checkWritePermission($modules.events.management.slug) ||
                      checkWritePermission($modules.events.schedule.slug)
                  "
            >
              <td class="text-left">Events Bookings</td>
              <td class="text-right">
                {{
                  Number(typeof ytd_E != "undefined" ? ytd_E.bookings : 0)
                      | numberFormatter
                }}
              </td>
            </tr>
            <tr
                v-if="
                    checkWritePermission($modules.events.management.slug) ||
                      checkWritePermission($modules.events.schedule.slug) ||
                    checkWritePermission($modules.facility.management.slug) ||
                      checkWritePermission($modules.facility.schedule.slug)
                  "
            >
              <td class="text-left">Total</td>
              <td class="text-right">
                {{
                  bookings | numberFormatter
                }}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </v-card>
    </v-col>
    <v-col lg="3" md="6" sm="6">
      <v-card class="rounded-lg shadow">
        <div>
          <div class="p-3 bg-neon text-white rounded-t d-flex justify-space-between align-center">
            <SvgIcon class="font-semibold" text="Sales">
              <template v-slot:icon>
                <WalletIcon/>
              </template>
            </SvgIcon>
            <p class="text-sm mb-0">
              Year to Date
            </p>
          </div>
          <table class="dash_tbl table-striped summary-table">
            <tbody>
            <tr
                v-if="
                    checkWritePermission($modules.facility.management.slug) ||
                      checkWritePermission($modules.facility.schedule.slug)
                  "
            >
              <td class="text-left">Facility Sales</td>
              <td class="text-right">
                {{
                  Number(typeof ytd_G != "undefined" ? ytd_G.sales : 0)
                      | toCurrency
                }}
              </td>
            </tr>
            <tr
                v-if="
                    checkWritePermission($modules.events.management.slug) ||
                      checkWritePermission($modules.events.schedule.slug)
                  "
            >
              <td class="text-left">Events Sales</td>
              <td class="text-right">
                {{
                  Number(typeof ytd_E != "undefined" ? ytd_E.sales : 0)
                      | toCurrency
                }}
              </td>
            </tr>
            <tr
                v-if="
                    checkWritePermission($modules.events.management.slug) ||
                      checkWritePermission($modules.events.schedule.slug) ||
                    checkWritePermission($modules.facility.management.slug) ||
                      checkWritePermission($modules.facility.schedule.slug)
                  "
            >
              <td class="text-left">Total</td>
              <td class="text-right">
                {{
                  sales | toCurrency
                }}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </v-card>
    </v-col>
    <v-col lg="3" md="6" sm="6">
      <v-card class="rounded-lg shadow">
        <div>
          <div class="p-3 bg-blue text-white rounded-t">
            <SvgIcon class="font-semibold" text="Bookings | Advance">
              <template v-slot:icon>
                <BookingIcon/>
              </template>
            </SvgIcon>
          </div>
          <table class="dash_tbl table-striped summary-table">
            <tbody>
            <tr
                v-if="
                    checkWritePermission($modules.facility.management.slug) ||
                      checkWritePermission($modules.facility.schedule.slug)
                  "
            >
              <td class="text-left">Facility Bookings</td>
              <td class="text-right">
                {{
                  Number(typeof adv_G != "undefined" ? adv_G.bookings : 0)
                      | numberFormatter
                }}
              </td>
            </tr>
            <tr
                v-if="
                    checkWritePermission($modules.events.management.slug) ||
                      checkWritePermission($modules.events.schedule.slug)
                  "
            >
              <td class="text-left">Events Bookings</td>
              <td class="text-right">
                {{
                  Number(typeof adv_E != "undefined" ? adv_E.bookings : 0)
                      | numberFormatter
                }}
              </td>
            </tr>
            <tr
                v-if="
                    checkWritePermission($modules.events.management.slug) ||
                      checkWritePermission($modules.events.schedule.slug) ||
                    checkWritePermission($modules.facility.management.slug) ||
                      checkWritePermission($modules.facility.schedule.slug)
                  "
            >
              <td class="text-left">Total</td>
              <td class="text-right">
                {{
                  advanceBookings | numberFormatter
                }}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </v-card>
    </v-col>
    <v-col lg="3" md="6" sm="6">
      <v-card class="rounded-lg shadow">
        <div>
          <div class="p-3 bg-neon text-white rounded-t">
            <SvgIcon class="font-semibold" text="Sales | Advance">
              <template v-slot:icon>
                <WalletIcon/>
              </template>
            </SvgIcon>
          </div>
          <table class="dash_tbl table-striped summary-table">
            <tbody>
            <tr
                v-if="
                    checkWritePermission($modules.facility.management.slug) ||
                      checkWritePermission($modules.facility.schedule.slug)
                  "
            >
              <td class="text-left">Facility Sales</td>
              <td class="text-right">
                {{
                  Number(typeof adv_G != "undefined" ? adv_G.sales : 0)
                      | toCurrency
                }}
              </td>
            </tr>
            <tr
                v-if="
                    checkWritePermission($modules.events.management.slug) ||
                      checkWritePermission($modules.events.schedule.slug)
                  "
            >
              <td class="text-left">Events Sales</td>
              <td class="text-right">
                {{
                  Number(typeof adv_E != "undefined" ? adv_E.sales : 0)
                      | toCurrency
                }}
              </td>
            </tr>
            <tr
                v-if="
                    checkWritePermission($modules.events.management.slug) ||
                      checkWritePermission($modules.events.schedule.slug) ||
                    checkWritePermission($modules.facility.management.slug) ||
                      checkWritePermission($modules.facility.schedule.slug)
                  "
            >
              <td class="text-left">Total</td>
              <td class="text-right">
                {{ advanceSales | toCurrency }}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import moment from "moment";
import {EventBus} from "@/main";
import WalletIcon from "@/assets/images/misc/wallet-money.svg";
import BookingIcon from "@/assets/images/misc/booking.svg";
import SvgIcon from "@/components/Image/SvgIcon.vue";

export default {
  components: {
    SvgIcon, BookingIcon, WalletIcon,
  },
  computed: {
    b2bConfig() {
      return this.$store.getters.getB2bConfig;
    },
    bookings(){
      let total = 0;
      if( this.checkWritePermission(this.$modules.events.management.slug) || this.checkWritePermission(this.$modules.events.schedule.slug)){
        total += Number(typeof this.ytd_E != "undefined" ? this.ytd_E.bookings : 0);
      }
      if(this.checkWritePermission(this.$modules.facility.management.slug) || this.checkWritePermission(this.$modules.facility.schedule.slug)){
        total += Number(typeof this.ytd_G != "undefined" ? this.ytd_G.bookings : 0);
      }
      return total;
    },
    advanceBookings(){
      let total = 0;
      if( this.checkWritePermission(this.$modules.events.management.slug) || this.checkWritePermission(this.$modules.events.schedule.slug)){
        total += Number(typeof this.adv_E != "undefined" ? this.adv_E.bookings : 0);
      }
      if(this.checkWritePermission(this.$modules.facility.management.slug) || this.checkWritePermission(this.$modules.facility.schedule.slug)){
        total += Number(typeof this.adv_G != "undefined" ? this.adv_G.bookings : 0);
      }
      return total;
    },
    sales(){
      let total = 0;
      if( this.checkWritePermission(this.$modules.events.management.slug) || this.checkWritePermission(this.$modules.events.schedule.slug)){
        total += Number(typeof this.ytd_E != "undefined" ? this.ytd_E.sales : 0);
      }
      if(this.checkWritePermission(this.$modules.facility.management.slug) || this.checkWritePermission(this.$modules.facility.schedule.slug)){
        total += Number(typeof this.ytd_G != "undefined" ? this.ytd_G.sales : 0);
      }
      return total;
    },
    advanceSales(){
      let total = 0;
      if( this.checkWritePermission(this.$modules.events.management.slug) || this.checkWritePermission(this.$modules.events.schedule.slug)){
        total += Number(typeof this.adv_E != "undefined" ? this.adv_E.sales : 0);
      }
      if(this.checkWritePermission(this.$modules.facility.management.slug) || this.checkWritePermission(this.$modules.facility.schedule.slug)){
        total += Number(typeof this.adv_G != "undefined" ? this.adv_G.sales : 0);
      }
      return total;
    }
  },
  data: () => ({
    allowProducts: 0,
    btnShow: false,
    stackType: "all",
    configDialog: false,
    dialog: false,
    show: false,
    events: null,
    barData: null,
    name: "FFFFF",
    company: null,
    email: null,
    job: null,
    phone: null,
    notes: null,
    barType: "week",
    pieType: null,
    deleter: false,
    deleteIndex: null,
    ytd_G: undefined,
    ytd_E: undefined,
    ytd_C: undefined,
    ytd_M: undefined,
    ytd_T: undefined,
    ytd_V: undefined,
    adv_G: undefined,
    adv_E: undefined,
    adv_C: undefined,
    adv_M: undefined,
    adv_T: undefined,
    adv_V: undefined,
    orderBy: "date",
    orderDir: "asc",
    latestCustomer: null,
    userModel: {userID: null, type: "details"},
    params: {venue_service_ids: [], product_ids: [], product_type_ids: []},
  }),
  mounted: function () {
    if (!this.$store.getters.configurationStatus("B2B")) {
      this.$store.dispatch("loadConfigurations", "B2B").then(() => {
        this.checkProductEnable();
      });
    } else {
      this.checkProductEnable();
    }
    this.init();
    EventBus.$on("reloadDashboard", () => {
      this.init();
    });
  },
  methods: {
    checkProductEnable() {
      if (this.b2bConfig && this.b2bConfig.enable_specific_products) {
        this.allowProducts = 1;
      } else {
        this.allowProducts = 0;
      }
    },
    init() {
      this.getDashboardCount();
    },
    getDashboardCount() {
      this.showLoader("Loading");
      let url = `?start_date=${moment()
          .startOf("year")
          .format("YYYY-MM-DD")}&end_date=${moment().format("YYYY-MM-DD")}`;
      this.$http
          .get(`venues/b2b/ytd-advance` + url)
          .then((res) => {
            if (res.status == 200) {
              let ytd = res.data.data.ytd;
              let advance = res.data.data.advance;
              this.ytd_G = ytd.find((y) => y.name == "Facility");
              this.ytd_E = ytd.find((y) => y.name == "Event");
              this.ytd_C = ytd.find((y) => y.name == "Academy");
              this.ytd_M = ytd.find((y) => y.name == "Membership");
              this.ytd_T = ytd.find((y) => y.name == "Trainer");
              this.ytd_V = ytd.find((y) => y.name == "Voucher");

              this.adv_C = advance.find((y) => y.name == "Academy");
              this.adv_E = advance.find((y) => y.name == "Event");
              this.adv_G = advance.find((y) => y.name == "Facility");
              this.adv_M = advance.find((y) => y.name == "Membership");
              this.adv_T = advance.find((y) => y.name == "Trainer");
              this.adv_V = advance.find((y) => y.name == "Voucher");
              this.hideLoader();
              this.$forceUpdate();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
  },
};
</script>

