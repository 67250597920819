var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"mt-3",attrs:{"md":"4"}},[_c('date-range-field',{attrs:{"dateType":_vm.dateToggle,"dense":true,"outlined":true,"class-name":"q-text-field shadow-0"},on:{"periodChange":_vm.dateChange}})],1),_c('v-spacer'),_c('v-col',{staticStyle:{"text-align":"center"},attrs:{"md":"3"}},[_c('v-btn-toggle',{staticClass:"q-tabs shadow-0 bordered",staticStyle:{"height":"40px !important"},attrs:{"mandatory":_vm.mandatory,"borderless":"","tile":""},model:{value:(_vm.dateToggle),callback:function ($$v) {_vm.dateToggle=$$v},expression:"dateToggle"}},[_c('v-btn',{attrs:{"height":"40","value":"DATE"}},[_vm._v("Day")]),_c('v-btn',{attrs:{"height":"40","value":"MONTH"}},[_vm._v("Month")]),_c('v-btn',{attrs:{"height":"40","value":"YEAR"}},[_vm._v("Year")])],1)],1),_c('v-spacer'),_c('v-col',{attrs:{"md":"4"}},[_c('div',{staticClass:"d-flex align-center justify-end gap-x-4"},[(_vm.showProductType)?_c('v-select',{staticClass:"q-autocomplete shadow-0",staticStyle:{"max-width":"180px !important"},attrs:{"items":_vm.productTypes,"menu-props":{ bottom: true, offsetY: true },"background-color":"#fff","item-text":"name","item-value":"id","placeholder":"Product Type","multiple":"","outlined":"","required":"","hide-details":"auto","dense":""},on:{"change":_vm.callGraph},scopedSlots:_vm._u([(_vm.productTypeIds.length == _vm.productTypes)?{key:"selection",fn:function(ref){
var index = ref.index;
return [(index == 0)?_c('span',[_vm._v("All")]):_vm._e()]}}:{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index == 0)?_c('span',[_vm._v(_vm._s(item.name))]):_vm._e(),(index == 1)?_c('span',[_vm._v(", ...")]):_vm._e()]}}],null,true),model:{value:(_vm.productTypeIds),callback:function ($$v) {_vm.productTypeIds=$$v},expression:"productTypeIds"}}):_vm._e(),(_vm.showVenueService)?_c('v-select',{staticClass:"q-autocomplete shadow-0",staticStyle:{"max-width":"180px !important"},attrs:{"items":_vm.venueServices,"menu-props":{ bottom: true, offsetY: true },"background-color":"#fff","dense":"","hide-details":"","item-text":"name","item-value":"venue_service_id","multiple":"","outlined":"","placeholder":"Service","required":""},on:{"change":_vm.callGraph},scopedSlots:_vm._u([(_vm.venueServiceIds.length === _vm.venueServices.length)?{key:"selection",fn:function(ref){
var index = ref.index;
return [(index === 0)?_c('span',[_vm._v("All")]):_vm._e()]}}:{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('span',{staticClass:"text-elepsis"},[_vm._v(_vm._s(item.name))]):_vm._e(),(index === 1)?_c('span',{staticClass:"text-elepsis"},[_vm._v(", ..")]):_vm._e()]}}],null,true),model:{value:(_vm.venueServiceIds),callback:function ($$v) {_vm.venueServiceIds=$$v},expression:"venueServiceIds"}}):_vm._e(),(_vm.showProductType)?_c('v-select',{staticClass:"q-autocomplete shadow-0",staticStyle:{"max-width":"180px !important"},attrs:{"items":_vm.stackTypesItems,"menu-props":{ bottom: true, offsetY: true },"background-color":"#fff","dense":"","hide-details":"","item-text":"name","item-value":"value","outlined":"","placeholder":"Stack Type","required":""},on:{"change":_vm.callGraph},model:{value:(_vm.stack),callback:function ($$v) {_vm.stack=$$v},expression:"stack"}}):_vm._e()],1)])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('BarChart',_vm._b({attrs:{"exportPermission":_vm.exportPermission}},'BarChart',_vm.bookings,false))],1),_c('v-col',{attrs:{"cols":"12"}},[_c('BarChart',_vm._b({attrs:{"exportPermission":_vm.exportPermission}},'BarChart',_vm.sales,false))],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }