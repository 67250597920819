<template>
  <v-container fluid no-gutters>
    <B2bTabs/>
    <B2bSummaryCards/>
    <v-row>
      <b2b-bookings-graph
        @stackChange="stackChangeCapture"
        :stackType="stackType"
        :showProductType="true"
        :exportPermission="checkExportPermission($modules.b2b.dashboard.slug)"
      ></b2b-bookings-graph>
    </v-row>
  </v-container>
</template>
<script>
import B2bBookingsGraph from "@/components/Chart/B2bBookingsGraph";
import B2bTabs from "@/views/B2b/B2bTabs.vue";
import B2bSummaryCards from "@/components/B2b/B2bSummaryCards.vue";
export default {
  components: {
    B2bSummaryCards,
    B2bTabs,
    "b2b-bookings-graph": B2bBookingsGraph,
  },
  computed: {
    b2bConfig() {
      return this.$store.getters.getB2bConfig;
    },
  },
  data: () => ({
    stackType: "all",
  }),
  methods: {

    stackChangeCapture(data) {
      this.stackType = data;
    },
  },
};
</script>

